import React, { Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { webRoutes } from "./webRoutes";
import pageRoutes, { filterRoute } from "@/configs/routes";
import PageNotFound from "@/components/PageNotFound";
import Layout from "@/pages/core/layout";
import { helper } from "@/services";
import Header from "@/pages/core/layout/header";

export default function InitialRoutes() {
	return (
		<>
			<Header />
			<Routes>
				<Route path="*" element={<PageNotFound />} />

				{webRoutes.public.map((route, i) => (
					<Route key={i} path={filterRoute(route.path)} element={<PublicRoutes component={route.element} {...route} />} />
				))}

				<Route path="/*" element={<Layout />}>
					{webRoutes.private.map((route, i) => (
						<Route key={i} path={filterRoute(route.path)} element={<PrivateRoutes component={route.element} {...route} />} />
					))}
				</Route>
			</Routes>
		</>
	);
}

function PublicRoutes({ component: Component }) {
	const location = useLocation();
	const isAuth = helper.isAuth();

	if (isAuth && location.pathname === pageRoutes.login) {
		return <Navigate to={pageRoutes.dashboard} />;
	}

	return (
		<Suspense fallback={"Loading..."}>
			<Component />
		</Suspense>
	);
}

function PrivateRoutes({ component: Component }) {
	let isAuth = helper.isAuth();
	return isAuth ? <Component /> : <Navigate to={pageRoutes.login} />;
}

import appConfig from "./appConfig";

const apiConfig = {
	baseURL: (appConfig.host + "/" + appConfig.prefix + "/").replace(/([^:]\/)\/+/g, "$1"),

	publicURL: (appConfig.host + "/").replace(/([^:]\/)\/+/g, "$1"),

	// auth apis
	login: "auth",
	document: "document/DOCUMENTS",
	read_data: "read_data",
	write_data:"write_data",
	get_document_type:"get_document_type",
	get_doc_schema:'get_doc_schema',
	add_document_type:'add_document_type',
	add_doc_schema:'add_doc_schema',
};

export default apiConfig;
import { createSlice } from "@reduxjs/toolkit";
import { appConfig } from "@/configs";
import { helper } from "@/services";

const initialState = {
	token: null,
	user: null,
	userName: null,
	password: null,
	isAuthenticated: false,
	isSelectConfig:'Established configurations'
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		handleLogin: (state, action) => {

			state.userName = action.payload.userName;
			state.password = action.payload.password;
			// window.localStorage.setItem(appConfig.localStorage.token, action.payload.access_token);
		},
		handleLogout: (state) => {
			// window.localStorage.removeItem(appConfig.localStorage.token);

			state.userName = null;
			state.password = null;
			state.isAuthenticated = false;
			helper.toaster.success("User logged out successfully");
		},
		isAuthenticated: (state, action) => {
			state.isAuthenticated = action.payload
		},
		isSelectConfigTable:(state, action)=>{
			state.isSelectConfig = action.payload
		}
	},
});

export default authSlice.reducer;
export const { handleLogin, handleLogout, isAuthenticated, isSelectConfigTable } = authSlice.actions;

const appConfig = {
	host: process.env.REACT_APP_API_HOST,
	prefix: process.env.REACT_APP_API_PREFIX,
	headers: {
		"Username": process.env.REACT_APP_API_USERNAME,
		"Password": process.env.REACT_APP_API_PASSWORD,
		// "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_HEADER_OCP_APIM_SUBSCRIPTION_KEY,
		// "Integrator-Key": process.env.REACT_APP_API_HEADER_INTEGRATOR_KEY
	},

	localStorage: {
		token: "bearer-token", // for saving bearer token
	},

	persistKey: {
		authReducer: "auth",
	},
};

export default appConfig;

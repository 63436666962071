import axios from "axios";
import { apiConfig, appConfig } from "../configs";
import { helper } from ".";
import { store } from "@/store";

const auth = btoa(`${appConfig.headers.Username}:${appConfig.headers.Password}`);

const getAuthHeader = () => {
	const state = store.getState();
	const { userName, password } = state.auth;
	return btoa(`${userName}:${password}`);
};

const instance = axios.create({
	baseURL: apiConfig.baseURL,
	headers: {
		"Content-Type": "application/json",
		Authorization: `Basic ${auth}`
	},
});

instance.interceptors.request.use(
	(config) => {
		const auth = getAuthHeader();
		if (auth) {
			config.headers.Authorization = `Basic ${auth}`
		}
		if (config.method === "post" || config.method === "put") {
			if (config.data instanceof FormData) {
				config.headers["Content-Type"] = "multipart/form-data";
			}
		}

		return config;
	},
	(error) => Promise.reject(error)
);
// handle errors
instance.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		// in the case, server is stoped
		if (error.code == "ERR_NETWORK") {
			// helper.toaster.error("Something went wrong, Please try after sometimes.");
		}

		return Promise.reject(error);
	}
);

const post = (url, data = {}, headers = {}) => instance.post(url, data, headers);

const destroy = (url) => instance.delete(url);

const get = (url, params = {}, other = {}) =>
	instance.get(url, {
		params,
		...other,
	});

const put = (url, data, headers = {}) => instance.put(url, data, headers);

export default {
	post,
	destroy,
	get,
	put,
};

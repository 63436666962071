import React, { useEffect, useState } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { pageRoutes, themeConfig } from "@/configs";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleLogout, isSelectConfigTable } from "@/store/toolkit/auth";
import { helper } from "@/services";
import { store } from "@/store";

export default function Header() {
  const router = useLocation();
  const dispatch = useDispatch();
  let isAuth = helper.isAuth();

  const state = store.getState();
  const { userName } = state.auth;

  let routerCheck = router.pathname === "/settings" ? "settings" : "Documents"

  const [activeDropdown, setActiveDropdown] = useState('Established configurations');
  const [activeMainDropDown, setActiveMainDropDown] = useState(routerCheck);


  const handleDropdownSelect = (eventKey) => {
    let newTitle = '';

    if (eventKey === 'established configurations') {
      newTitle = 'Established configurations';
    } else {
      newTitle = 'New Configurations';
    }

    setActiveDropdown(newTitle);
    dispatch(isSelectConfigTable(newTitle))
  };


  const handleMainDropdownSelect = (eventKey) => {
    let newTitle = '';

    if (eventKey === 'settings') {
      newTitle = 'settings';
    } else {
      newTitle = 'Documents';
    }
    setActiveMainDropDown(newTitle);
  };

  useEffect(() => {
    dispatch(isSelectConfigTable('Established configurations'));
  }, [])

  return (
    <section className="header--section">
      <Navbar expand="lg">
        <Container fluid>
          <Navbar.Brand
            as="img"
            src={themeConfig.images.Logo}
            className="me-0 logo--img p-0"
          />
          {
            isAuth && (
              <>
                <Nav className="w-100">
                  <NavDropdown
                    title={activeMainDropDown}
                    className="text-capitalize p-0 menu--list text-decoration-none"
                    onSelect={handleMainDropdownSelect}
                  >
                    {/* <NavDropdown.Item
                      href={pageRoutes.dashboard}
                      className="text-capitalize text-decoration-none"
                    >
                      Dashboard
                    </NavDropdown.Item> */}
                    <NavDropdown.Item
                      href="/"
                      className="text-capitalize text-decoration-none"
                      eventKey="documents"
                    >
                      Documents
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/settings"
                      eventKey="settings"
                      className="text-capitalize text-decoration-none"
                    >
                      Settings
                    </NavDropdown.Item>
                  </NavDropdown>
                  {router.pathname == pageRoutes.documents && (
                    <NavDropdown
                      title={activeDropdown}
                      className="text-capitalize p-0 menu--list text-decoration-none"
                      onSelect={handleDropdownSelect}
                    >
                      <NavDropdown.Item
                        eventKey="established configurations"
                        className="text-capitalize text-decoration-none"
                      >
                        Established configurations
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        eventKey="new-configurations"
                        className="text-capitalize text-decoration-none"
                      >
                        New Configurations
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                </Nav>
                <NavDropdown
                  title={
                    <div className="d-flex align-items-center">
                      <span className="d-flex align-items-center text-white">{userName}</span>
                      <Icon
                        icon="mingcute:user-4-fill"
                        className="d-block text-white-primary mx-2"
                      />
                    </div>

                  }
                  className="account--dropdown"
                >
                  <NavDropdown.Item href="/login" onClick={() => dispatch(handleLogout())}>Sign Out</NavDropdown.Item>
                </NavDropdown>
              </>
            )}
        </Container>
      </Navbar>
    </section>
  );
}

import { pageRoutes } from "@/configs";
import { lazy } from "react";

const Login = lazy(() => import("../pages/core/login"));
const DashboardPage = lazy(() => import("./../pages/Dashboard"));
const DocumentsPage = lazy(() => import("./../pages/Documents"));
const SettingsPage = lazy(() => import("./../pages/Settings"));

export const webRoutes = {
	private: [
		{
			path: pageRoutes.dashboard,
			element: DocumentsPage, // add temporarily
		},
		{
			path: pageRoutes.documents,
			element: DocumentsPage,
		},
		{
			path: pageRoutes.settings,
			element: SettingsPage,
		},
	],

	public: [
		{
			path: pageRoutes.login,
			element: Login,
		},
	],
};
